import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_CONFIG_OV

export default class ModalidadContratoService {
  listarModalidades (params) {
    return http.get(`${baseUrl}/mod-contrato/search`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  search (search = null) {
    return http.get(`${baseUrl}/mod-contrato/search${search ? `/${search}` : ''}`, {
      headers: {
        loading: true
      }
    })
  }

  paginate (params) {
    return http.get(`${baseUrl}/mod-contrato/paginate`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  post (body) {
    return http.post(`${baseUrl}/mod-contrato`, body, {
      headers: {
        loading: true
      }
    })
  }

  put ({ id, body }) {
    return http.put(`${baseUrl}/mod-contrato/${id}`, body, {
      headers: {
        loading: true
      }
    })
  }

  delete (id) {
    return http.delete(`${baseUrl}/mod-contrato/${id}`, {
      headers: {
        loading: true
      }
    })
  }
}
